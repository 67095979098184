import { getToken, getID, getSede } from './Usuario';

export function GetData(type){    

    const url_api = process.env.REACT_APP_URL_SERVICIOS;
    const token = getToken();
    const id = getID();
    const sede = getSede();

    return new Promise((resolve, reject) => {        
        fetch(url_api+type+id+'/'+token+'/'+sede)   
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
           reject(error);
        })
    })    
}