import React, { useEffect, useState, useContext } from 'react'
import { Grid, Typography, LinearProgress, Card, CardHeader, Container, CardContent, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import { toast } from 'react-toastify';

import { PostData } from '../services/PostData';
import { SessionContext } from '../SessionContext';
import { GetData } from '../services/GetData';
import { getToken } from '../services/Usuario';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'


const ListadoAlumnos = () => {
  
    const { idGrado = null, idGrupo = null} = useParams();
    const [Alumnos, setAlumnos] = useState([]);
    const [ListaGrados, setListaGrados] = useState([]);
    const [ListaGrupos, setListaGrupos] = useState([]);
    const [Grado, setGrado] = useState("");
    const [Loading, setLoading] = React.useState(false);
    const { user } = useContext(SessionContext);
    const [openDatos, setOpenDatos] = React.useState(false);

    const [IdAlumno, setIdAlumno] = useState("0")
    const [IdMatricula, setIdMatricula] = useState("0")
    const [UrlCertificado, setUrlCertificado] = useState("");
    const [DGrado, setDGrado] = useState("");
    const [DGrupo, setDGrupo] = useState("");
    const [Nombres, setNombres] = useState("")
    const [Identificacion, setIdentificacion] = useState("")
    const [Celular, setCelular] = useState("")
    const [Direccion, setDireccion] = useState("")
    const [FechaN, setFechaN] = useState("")
    const [Dcorreo, setDCorreo] = useState("")
    const [Estado, setEstado] = useState("1")
    const [Sexo, setSexo] = useState("-")
    const [NumMatricula , setNumMatricula ] = useState("")
    const [formValidado, setformValidado] = useState(false);
    const [LoadingSave, setLoadingSave] = useState(false)


    

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          padding: '6px 16px', // Establece el padding en cero
          color: theme.palette.common.white,
          backgroundColor: '#1976d2',
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          padding: '4px 16px', // Establece el padding en cero
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      
      const validateFechaNacimiento = (fecha) => {
        const fechaNacimiento = new Date(fecha);
        const fechaActual = new Date();
        return fechaNacimiento < fechaActual;
      };

      const irA = (url, externa=false) => {
            window.open(url, "_blank");    
      }

    useEffect(() => {
          GetData('grados/todos/', null).then ((result) =>{
              let responseJSON = result;
              if (responseJSON.error === false){                      
                  setListaGrados(result.grados)
              }else{
                
              }
          });
    }, [])  

    useEffect(() => {
          GetData('grupos/todos/', null).then ((result) =>{
              let responseJSON = result;
              if (responseJSON.error === false){                      
                  setListaGrupos(result.grupos)
              }else{
                
              }
          });
    }, [])  

    useEffect(() => {
        const fetchData = async () => {
          const datos = {
            'id_grado': idGrado,
            'id_grupo' : idGrupo
          };
      
          const result = await PostData(`matriculas/alumnosgradoadmin/`, datos);
          const responseJSON = result;
      
          if (responseJSON.error === false) {
            setAlumnos(responseJSON.alumnos);
            setGrado(responseJSON.grado);
            setLoading(false);
          } else {
            console.log("Error al cargar las asignaciones");
          }
        };
      
        fetchData();
        if (idGrado < 14)
            setUrlCertificado('certificado_tc_6_9.php')
        else
            setUrlCertificado('certificado_tc_10_11.php')

      }, [idGrado, idGrupo]);

      const listado_alumnos = async () => {

        const datos = {
          'id_grado': idGrado,
          'id_grupo' : idGrupo
        };
    
        const result = await PostData(`matriculas/alumnosgradoadmin/`, datos);
            const responseJSON = result;
        
            if (responseJSON.error === false) {
              setAlumnos(responseJSON.alumnos);
              setGrado(responseJSON.grado);
              setLoading(false);
            } else {
              console.log("Error al cargar las asignaciones");
            }
        
      }
      
      const DatosAlumno = (id) => {
        setLoadingSave(true)
        setDGrado("0")
        setIdAlumno("0")
        setIdMatricula("0")
        setNombres("")
        setIdentificacion("")
        setCelular("")
        setDireccion("")
        setFechaN("")
        setDCorreo("")
        setEstado("1")
        setOpenDatos(true)
        GetData('matriculas/datosmatricula/' + id + '/', null).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){    
                const datos = result.matricula;        
                setIdMatricula(datos.id_matricula)
                setIdAlumno(datos.id_alumnos)
                setNumMatricula(datos.num_matricula)
                setDCorreo(datos.email_al)
                setNombres(datos.nombres_apellidos_al)
                setIdentificacion(datos.num_documento_al)
                setCelular(datos.telefono_al)
                setDireccion(datos.direccion_al)
                setFechaN(datos.fecha_nacimiento_al)
                setEstado(datos.id_estado_matricula)     
                setDGrado(datos.cod_grado)                                        
                setDGrupo(datos.id_grupo)                                        
                setSexo(datos.sexo)                                        
                setLoadingSave(false)
            }else{
               
            }
        });
        
  }

  useEffect(() => {
    setformValidado(false)
    if (Nombres && emailRegex.test(Dcorreo) && Identificacion && Celular && Direccion){
        setformValidado(true)
    }
  }, [Nombres, Dcorreo, Identificacion, Celular, Direccion])

  const handleDatosClose = () => {
    setOpenDatos(!openDatos);
  };

  const GuardarAlumno = () => {

    const datos={       
        id_matricula:IdMatricula,     
        id_alumno:IdAlumno,     
        nombre:Nombres,
        identificacion:Identificacion,
        celular:Celular,
        direccion:Direccion,
        fecha_nac:FechaN,
        email:Dcorreo,
        grado:DGrado,
        grupo:DGrupo,
        estado:Estado
    }
    
    setLoadingSave(true)
    PostData('matriculas/guardar/', datos).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
            
            setLoadingSave(false)
            toast.success('Alumnos guardado con exito', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                listado_alumnos();
        }else{
            setLoadingSave(false)
            toast.error(responseJSON.mensaje, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
           
        }
    });
}

  return (
    <>
     <Container maxWidth="lg">
        <Card sx={{marginTop: '20px'}}>
              <div className='barra_titulo' >
                  <Grid container spacing={2}>
                       <Grid item xs={4}>
                            <Typography variant="h6" color="inherit">Listado de Alumnos</Typography>                                            
                        </Grid>
                        <Grid item xs={3} sx={{paddingTop:'10px'}}>
                              <span ><strong>Grado:</strong> {Grado} - 0{idGrupo}</span>
                        </Grid>
                        <Grid item xs={2}>
                              <span className='negrita'>N° Alumnos: </span>  {Alumnos.length}
                        </Grid>
                    </Grid>
              </div>
            <CardContent>          
                    {Loading && <LinearProgress  />}
                    <TableContainer component={Paper} size="small" >
                    <Table size="small" aria-label="customized table">
                        <TableHead className='headerTable'>
                        <TableRow>
                            <StyledTableCell>N°</StyledTableCell>
                            <StyledTableCell align="left">Alumno</StyledTableCell>
                            <StyledTableCell align="left">Teléfono</StyledTableCell>
                            <StyledTableCell align="left">Dirección</StyledTableCell>
                            <StyledTableCell align="left">Estado</StyledTableCell>
                            <StyledTableCell align="left"></StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {Alumnos.map((row, index) => (
                            <StyledTableRow key={row.id_matricula}>
                            <StyledTableCell component="th" scope="row" width="100px">
                                {row.id_matricula}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.nombres_apellidos_al}</StyledTableCell>
                            <StyledTableCell align="left">{row.telefono_al}</StyledTableCell>
                            <StyledTableCell align="left">{row.direccion_al}</StyledTableCell>
                            <StyledTableCell align="left">{row.nombre_estado}</StyledTableCell>
                            <StyledTableCell align="center">
                              <Tooltip title="Datos del Alumno">
                                <IconButton aria-label="Datos del Alumno" color='primary' onClick={() => DatosAlumno(row.id_matricula)}>
                                  <HowToRegIcon />
                                </IconButton>
                              </Tooltip>                       
                              <Tooltip title="Listado Asistencia PDF">
                                <IconButton aria-label="Listado Asistencia" color='primary' onClick={() => irA(process.env.REACT_APP_URL_INFORMES + '/listado_alumnos_asistenc.php?grado='+idGrado+'&grupo='+Number(idGrupo)+'&token='+getToken()+'&db='+user.sede, true)}>
                                  <TextSnippetIcon />
                                </IconButton>
                              </Tooltip>                       
                              <Tooltip title="Listado Asistencia Excel">
                                <IconButton aria-label="Listado Excel" color='primary' onClick={() => irA(process.env.REACT_APP_URL_INFORMES + '/lisadoalumnosdatos_xls.php?grado='+idGrado+'&grupo='+Number(idGrupo)+'&token='+getToken()+'&db='+user.sede, true)}>
                                    <FontAwesomeIcon icon={faFileExcel} />
                                    
                                </IconButton>
                              </Tooltip>                       
                              <Tooltip title="Certificado Alumno">
                                <IconButton aria-label="Certificado" color='primary' onClick={() => irA(process.env.REACT_APP_URL_INFORMES + '/' + UrlCertificado + '?idmatricula='+row.id_matricula+'&grado='+idGrado+'&grupo='+Number(idGrupo)+'&token='+getToken()+'&db='+user.sede, true)}>
                                    <FilterFramesIcon  />
                                    
                                </IconButton>
                              </Tooltip>                       
                            </StyledTableCell>

                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
            </CardContent>                
          </Card>
       </Container>     

       <Dialog
            open={openDatos}
            onClose={handleDatosClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'common.white' }} >Datos Alumno - Matricula N° {NumMatricula}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {LoadingSave ?  <LinearProgress /> :
                <Grid container spacing={2} >
                     <Grid item xl={3}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="estado_matricua">Estado</InputLabel>
                            <Select
                              labelId="estado_matricua"
                              id="estado_matricua"
                              value={Estado}
                              onChange={(e) => setEstado(e.target.value)} 
                              label="Age"
                            >
                              <MenuItem value={1}>Activo</MenuItem>
                              <MenuItem value={2}>Retirado</MenuItem>
                              <MenuItem value={3}>Desertado</MenuItem>
                            </Select>
                          </FormControl>
                     </Grid> 
                     <Grid item xl={3}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="grado">Grado</InputLabel>
                            <Select
                              labelId="grado"
                              id="grado"
                              value={DGrado}
                              onChange={(e) => setDGrado(e.target.value)} 
                              label="Grado"
                            >
                              {ListaGrados.map(grado => <MenuItem key={grado.id_grados} value={grado.id_grados}>{grado.nombre_grado}</MenuItem>)}                              
                            </Select>
                          </FormControl>
                     </Grid>
                     <Grid item xl={3}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="grado">Grupo</InputLabel>
                            <Select
                              labelId="grupo"
                              id="grado"
                              value={DGrupo}
                              onChange={(e) => setDGrupo(e.target.value)} 
                              label="Grupo"
                            >
                              {ListaGrupos.map(grado => <MenuItem key={grado.id_grupo} value={grado.id_grupo}>{grado.num_grupo}</MenuItem>)}                              
                            </Select>
                          </FormControl>
                     </Grid>
                     <Grid item xs={8}>
                            <TextField fullWidth 
                                        id="nombres" 
                                        label="Nombres y Apellidos" 
                                        value={Nombres} 
                                        onChange={(e) => setNombres(e.target.value)} 
                                        helperText={Nombres === "" ? "El campo no puede estar vacío" : ""}
                                        error={Nombres === ""}
                                        variant="standard" />
                     </Grid>   
                     <Grid item xs={4}>
                            <TextField fullWidth id="identificacion" label="Identificación" 
                                        value={Identificacion} 
                                        helperText={Identificacion === "" ? "El campo no puede estar vacío" : ""}
                                        error={Identificacion === ""}
                                        onChange={(e) => setIdentificacion(e.target.value)} 
                                        variant="standard" />
                     </Grid>   
                     <Grid item xs={4}>
                            <TextField fullWidth id="celular"
                                                 label="Célular" 
                                                 variant="standard"
                                                  value={Celular} 
                                                  helperText={Celular === "" ? "El campo no puede estar vacío" : ""}
                                                  error={Celular === ""}
                                                  onChange={(e) => setCelular(e.target.value)} /> 
                     </Grid>   
                     <Grid item xs={8}>
                            <TextField fullWidth 
                                            id="direccion" 
                                            label="Dirección" 
                                            variant="standard" 
                                            value={Direccion} 
                                            helperText={Direccion === "" ? "El campo no puede estar vacío" : ""}
                                            error={Direccion === ""}
                                            onChange={(e) => setDireccion(e.target.value)}  />
                     </Grid>   
                     
                     <Grid item xs={4}>
                            <TextField fullWidth type='date' 
                                        id="fechan" 
                                        label="Fecha Nacimiento" 
                                        variant="standard" 
                                        value={FechaN} 
                                        error={!validateFechaNacimiento(FechaN)}
                                        onChange={(e) => setFechaN(e.target.value)}  />
                     </Grid>   
                     <Grid item xs={8}>
                            <TextField fullWidth type='email' id="correo" label="Correo electrónico" variant="standard" value={Dcorreo} 
                                    onChange={(e) => setDCorreo(e.target.value)}
                                    error={!emailRegex.test(Dcorreo)} // Validar el formato de correo utilizando la expresión regular
                                    helperText={!emailRegex.test(Dcorreo) ? "Ingrese un correo electrónico válido" : ""}
                                    />
                     </Grid>      
                     <Grid item xs={3}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="sexo_matricua">Sexo</InputLabel>
                            <Select
                              labelId="sexo_matricua"
                              id="sexo_matricua"
                              value={Sexo}
                              onChange={(e) => setSexo(e.target.value)} 
                              label="Sexo"
                            >
                              <MenuItem value={'-'}>Seleccionar</MenuItem>
                              <MenuItem value={'M'}>Masculino</MenuItem>
                              <MenuItem value={'F'}>Femenino</MenuItem>
                            </Select>
                          </FormControl>
                     </Grid>                 
                </Grid>
                }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={GuardarAlumno} color="primary" disabled={!formValidado}>
                Guardar
            </Button>
            <Button onClick={handleDatosClose} color="secondary">
                Cerrar
            </Button>
            </DialogActions>
      </Dialog>
    </>
  )
}

export default ListadoAlumnos;