import { Card, CardHeader, CardContent, Grid, Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody, LinearProgress, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material/';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { SessionContext } from '../SessionContext';
import { GetData } from '../services/GetData';
import { PostData } from '../services/PostData';

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const Consolidados = () => {
    const { user } = useContext(SessionContext);
    const { idGrado, idGrupo } = useParams ();
    const [ListaMaterias, setListaMaterias] = useState([])
    const [ListaPeriodo, setListaPeriodo] = useState([])
    const [ListaConsolidado, setListaConsolidado] = useState([])
    const [Loading, setLoading] = useState(false)
    const [Filas, setFilas] = useState(false)
    const [Periodo, setPeriodo] = useState(user.periodo) //user.periodo
    const [NomGrado, setNomGrado] = useState("") //user.periodo
    
    //const { idGrado, idGrupo } = location.state;
    

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        //backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        backgroundColor: '#1976d2'
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));

    const consultar_consolidado = () => {
            const datos = {
              cod_grado: idGrado, 
              id_grupo: parseInt(idGrupo), 
              id_periodo: Periodo
            }  
            setLoading(true)
            PostData('consolidados/consolidadoperiodoad/',datos).then ((result) =>{                
                if (result.error === false){     
                  const resultado = result.consolidado;        
                  setListaConsolidado(resultado.filas)                  
                  setListaMaterias(resultado.columnas)     
                  setFilas(resultado.filas);         
                  setNomGrado(result.nom_grado)    
                  setLoading(false)
                }
            });
    }

    useEffect(() => {
        let listap = []
        for (let index = 0; index < user.periodo; index++) {
             listap.push(index+1);           
        }
        setListaPeriodo(listap)    
    }, [])

    useEffect(() => {
        consultar_consolidado()
    }, [Periodo])


    const handleSelectChange = (event) => {
      const selectedValue = event.target.value;
        
      switch (selectedValue) {
        case 1:
               window.open(process.env.REACT_APP_URL_INFORMES + '/consolidado_periodo.php?grado='+idGrado+'&grupo='+idGrupo+'&periodo='+Periodo+'&db='+user.sede, '_blank');  
               break;
        case 2:
               window.open(process.env.REACT_APP_URL_INFORMES + '/consolidados_puesto.php?grado='+idGrado+'&grupo='+idGrupo+'&periodo='+Periodo+'&db='+user.sede, '_blank');  
               break;
        case 3:
               window.open(process.env.REACT_APP_URL_INFORMES + '/consolidados_prom.php?grado='+idGrado+'&grupo='+idGrupo+'&periodo='+Periodo+'&db='+user.sede, '_blank');  
               break;
        case 4:
               window.open(process.env.REACT_APP_URL_INFORMES + '/consolidados_excelpro.php?grado='+idGrado+'&grupo='+idGrupo+'&periodo='+Periodo+'&db='+user.sede, '_blank');  
               break;
      }
    };
    

  return (
    <Container maxWidth="lg">

        <Card sx={{marginTop: '25px'}}>
            <CardHeader sx={{
                        background: 'linear-gradient(90deg, rgba(25,118,210,1) 60%, rgba(138,184,228,1) 100%)',
                        color: 'primary.contrastText',
                        borderBottom: '1px solid',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',                        
                    }}  
                title={`Consolidados ${NomGrado} - ${idGrupo}`} 
                />
                
            
            <CardContent>
               {/*  <Alert severity="success">
                {ListaMaterias.map((asig, index) => (
                <span className="materias fontTabla" key={`span${index}`}>(<strong>{index}</strong>) {asig.asig}</span>
                ))}
                </Alert> */}
                 <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                    <Grid item xs={2}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="sel_periodo">Periodos</InputLabel>
                        <Select
                          labelId="sel_periodo"
                          id="sel_periodo"
                          value={Periodo}
                          onChange={(e)=> setPeriodo(e.target.value)}
                          label="Periodos"
                        >
                          {ListaPeriodo.map((periodo,index) => (
                            <MenuItem key={`peri_${index}`} value={periodo}>
                              Periodo {periodo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>  
                    </Grid>
                      <Grid item xs={2}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="sel_periodo">Exportar Consolidado</InputLabel>
                            <Select
                              labelId="sel_consolidado"
                              id="sel_consolidado"
                              label="Consolidados"
                              value={0}
                              onChange={handleSelectChange}
                            >

                                <MenuItem value={0}>
                                  <em>Seleccione</em>
                                </MenuItem>
                                <MenuItem key={`infor_1`} value={1}>
                                    Consolidado por Periodo
                                </MenuItem>
                                <MenuItem key={`infor_2`} value={2}>
                                    Consolidado por Periodo y Puesto
                                </MenuItem>
                                <MenuItem key={`infor_3`} value={3}>
                                    Consolidado Promedio por Período
                                </MenuItem>
                                <MenuItem key={`infor_3`} value={4}>
                                    Consolidado Promedio por Período Excel
                                </MenuItem>
                              
                            </Select>
                          </FormControl>  
                  </Grid>
              </Grid>
                {Loading && <LinearProgress  />}

                <TableContainer component={Paper}>
                <Table size="small" aria-label="customized table">
                  <TableHead className='headerTable'>
                    <TableRow>
                      <StyledTableCell style={{ width: "5%" }}>Cod</StyledTableCell>
                      <StyledTableCell style={{ width: "45%" }}>Nombre del estudiante</StyledTableCell>
                      {ListaMaterias.slice(2).map((materia, index) => (
                        <Tooltip key={`tooltip-${index}`} title={materia.doc !== undefined ? materia.asig + ', Doc: ' + materia.doc : ''}><StyledTableCell sx={{fontSize:'12px'}} key={index}>{materia.abrev}</StyledTableCell></Tooltip>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {ListaConsolidado.map((fila, index) => (
                      <StyledTableRow key={index}>
                        {fila.map((dato, index) => (
                          <StyledTableCell key={index} className={index >= 2 && index < ListaMaterias.length - 1 && dato < 3 ? "texto-rojo" : ""}>{dato}</StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                </TableContainer>

            </CardContent>
        </Card>
    </Container>

  )
}

export default Consolidados