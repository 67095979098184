import { Button, Card, CardContent, CardHeader, Container, Grid, LinearProgress } from '@mui/material/'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PostData } from '../services/PostData'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ItemDocentePorc from './ItemDocentePorc'


const DocentesPorcenIngreso = () => {

    const [listaDocentes, setListaDocentes] = useState([]);
    const [loading, setLoading] = useState(false)
   
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {          
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            background: 'linear-gradient(90deg, rgba(9,53,121,1) 60%, rgba(138,184,228,1) 100%)',
            color: theme.palette.common.white,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));


    useEffect(() => {
        listado_alumno()
    }, [])
    
      const listado_alumno = () => {
        const datos = {

        }
        
        setLoading(true)
        PostData('docentes/lista/', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                setListaDocentes(responseJSON.docentes);
                setLoading(false)
                
            }
        });

      }


  return (
    <>
    <Container maxWidth="lg">

        <Card>
            <CardHeader color="warning">
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                    <h4 className="H4tabla" >Listado de Docentes</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <Button type="button" variant="contained" className="MargenBoton" component={Link} to={`/home/`} color="primary"> Regresar </Button>
                    </Grid>
                </Grid>
            </CardHeader>
            <CardContent>
                <LinearProgress style={{visibility: loading ? 'visible' : 'hidden' }} />
                
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <StyledTableRow>
                                  <StyledTableCell key="{th0}" width="5%" >Código</StyledTableCell>
                                  <StyledTableCell key="{th1}" width="30%" align="left">Nombre del docente</StyledTableCell>
                                  <StyledTableCell key="{th2}" width="30%" align="left">Célular</StyledTableCell>
                                  <StyledTableCell key="{th3}" width="20%" align="left">Porcentaje</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                                
                                  <ItemDocentePorc listaDocentes={listaDocentes} />
                                    
                              </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
        </Container>      

       
    </>
  )
}

export default DocentesPorcenIngreso