import Rutas from './Rutas';
import './App.css'
import { SessionProvider } from './SessionContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
 

  return (
        <SessionProvider>
          <Rutas />
          <ToastContainer />
        </SessionProvider>
  );
}

export default App;
