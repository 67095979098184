import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CallReceived from '@mui/icons-material/CallReceived';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import Check from '@mui/icons-material/Check';
import ListIcon from '@mui/icons-material/List';

import { Button, CardActions, Divider, LinearProgress, Menu, MenuItem } from '@mui/material';
import { deepOrange, green, pink } from '@mui/material/colors';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useNavigate } from 'react-router-dom';
import { getToken, getSede } from '../services/Usuario';


const CardGrado = ({grado, grupo, textogrado, color, title, number, num_asig, porc_notas, num_desert, periodo_actual }) => {

    const [expanded, setExpanded] = React.useState(false);
    const [Color, setColor] = React.useState(deepOrange[500]);
    const navigate = useNavigate();
    const pagina = parseInt(periodo_actual) === 4 ? 'informes-final.php' : 'informes.php'

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    

    React.useEffect(() => {
      
      if (grado < 10)
         setColor(deepOrange[500])
      if (grado >= 10 && grado < 14)
         setColor(green[500])
      if (grado >= 14)   
         setColor(pink[500])
      
    }, [])
    
    const irA = (url, externa=false) => {
      if (!externa)
          navigate(url);
      else
          window.open(url, "_blank");    
    }

    return (
      <Card sx={{ maxWidth: 450, boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14);' }}>
        <CardHeader
          avatar={
            <Avatar className='avatarCardGrado' sx={{    bgcolor: Color,  
                                                    marginTop: '-35px',  
                                                    position: 'absolute',  
                                                    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4) !important', 
                                                    borderRadius: '6px', padding:'2px 15px' 
                                            }} aria-label="recipe" variant="rounded">
                                            <span className='titulGrado'>{textogrado}</span>  
            </Avatar>
          }
         
          titleTypographyProps={{ variant: 'h5', textAlign: 'right' }}
          subheaderTypographyProps={{ textAlign: 'right', color: '#999' }}
          title={number}
          subheader={title}
        />
       
        <CardContent>
                  <h4 className="cardGradoTitle">Detalles adicionales</h4>
                  <p className="cardCategory">                     
                  <Typography variant="body1" style={{ fontSize: '14px', color:'#4caf50' }} >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <LibraryBooks style={{ marginRight: '5px' }} />
                      <span style={{ fontSize: '14px', alignSelf: 'center' }}>{num_asig}</span>
                    </div>
                  </Typography>
  
                      <span>No. Asignaturas</span>
                  </p>                                    
                  <p className="cardCategory">     
                      <Typography style={{ fontSize: '14px', color:'#4caf50' }} variant="body1" >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Check style={{ marginRight: '5px' }} /> 
                        <span style={{ fontSize: '14px', alignSelf: 'center' }}>{porc_notas}%</span>
                      </div>                      
                      </Typography>                           
                      <span>Notas ingresadas.</span>                      
                  </p>
                  <LinearProgress style={{ fontSize: '14px' }} className="progress_nota_grado" variant="determinate" value={porc_notas} color="secondary" />
                  <p className="cardCategory">                                            
                      <Typography style={{ fontSize: '14px' }} variant="body1" color="error">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                      <CallReceived style={{ marginRight: '5px' }}  />
                      <span style={{ fontSize: '14px', alignSelf: 'center' }}> {num_desert}</span>
                      </div>
                      </Typography>                                       
                      <span>Retirados</span>
                  </p> 
     

             
            
          

        </CardContent>
         <CardActions>
            <Divider /> 
            <Button variant="outlined" size="small" onClick={()=>irA('/home/consolidado/'+grado+'/'+grupo)} >
                Consolidado
            </Button>
            <Button variant="outlined" size="small" onClick={()=>irA(process.env.REACT_APP_URL_INFORMES + '/' + pagina + '?grado='+grado+'&grupo='+Number(grupo)+'&periodo='+periodo_actual+'&token='+getToken()+'&db='+getSede(), true)}>
              Informe
            </Button>

            <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <IconButton aria-label="delete" {...bindTrigger(popupState)}>
                    <ListIcon aria-label="Example"  />                                   
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={()=>irA('/home/listadoalumnos/'+grado+'/'+Number(grupo))}>Lista Alumnos</MenuItem>
                  <MenuItem onClick={()=>irA(process.env.REACT_APP_URL_INFORMES + '/consolidados-final.php?grado='+grado+'&grupo='+Number(grupo)+'&periodo=1&token='+getToken()+'&db='+getSede(), true)}>Consolidado Final</MenuItem>
                  <MenuItem onClick={()=>irA(process.env.REACT_APP_URL_INFORMES + '/listado_alumnos_firma.php?grado='+grado+'&grupo='+Number(grupo)+'&periodo=1&token='+getToken()+'&db='+getSede(), true)}>Asistencia Padres</MenuItem>
                {/*   <MenuItem onClick={popupState.close}>Certificados</MenuItem>
                  <MenuItem onClick={popupState.close}>Listado Puestos Final</MenuItem> */}
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
         </CardActions> 
        
      </Card>
    
  );
};

export default CardGrado;
