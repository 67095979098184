import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Person from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { Divider } from '@mui/material';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardPersonalizada = ({ color, title, number, icon, fechacierre, p_cerrado, dias_faltantes_cierre }) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
  
    return (
      <Card sx={{ maxWidth: 450, boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14);' }}>
        <CardHeader
          avatar={
            <Avatar className='avatarCard' sx={{    bgcolor: color,  
                                                    marginTop: '-65px',  
                                                    position: 'absolute',  
                                                    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4) !important', 
                                                    borderRadius: '6px', padding:'25px' 
                                            }} aria-label="recipe" variant="rounded">

                                                          
            {icon === 1 && <Person sx={{width: '36px', height:'36px', margin: '10px 10px 4px'}} />}
            {icon === 2 && <GroupRemoveIcon sx={{width: '36px', height:'36px', margin: '10px 10px 4px'}} />}
            {icon === 3 && <AssignmentIndIcon sx={{width: '36px', height:'36px', margin: '10px 10px 4px'}} />}
            {icon === 4 && <AlarmOnIcon sx={{width: '36px', height:'36px', margin: '10px 10px 4px'}} />}
              
            </Avatar>
          }
         
          titleTypographyProps={{ variant: 'h5', textAlign: 'right' }}
          subheaderTypographyProps={{ textAlign: 'right', color: '#999' }}
          title={number}
          subheader={title}
        />
       
        <CardContent>
            <Divider />  
            {fechacierre && <span className='infoperiodo'>F. de cierre: {fechacierre} 
                                    <span className={p_cerrado ? "p_cerrado": "p_abierto"}>
                                         ({dias_faltantes_cierre})
                                    </span></span>}
        </CardContent>

        
      </Card>
    
  );
};

export default CardPersonalizada;
