import React from 'react';
import { red, lightBlue, green, orange } from '@mui/material/colors';
import {  Grid } from '@mui/material';
import CardPersonalizada from '../../../components/CardPersonalizada';

const BardInfo = (props) => { 

    

    return (<>
                    <Grid container spacing={2} >
                            <Grid item xs={12} sm={6} md={3}>
                                <CardPersonalizada icon={1} color={lightBlue[500]} title="Alumnos Matriculados" number={props.num_alumnos_matr}></CardPersonalizada>                            
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <CardPersonalizada icon={2} color={red[500]} title="Retirados" number={props.num_alumnos_retir}></CardPersonalizada>                            
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <CardPersonalizada icon={3} color={green[500]} title="Docentes" number={props.num_docentes}></CardPersonalizada>                            
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <CardPersonalizada icon={4} color={orange[500]} title="Período" 
                                                            fechacierre={props.fecha_cierre_p} 
                                                            p_cerrado={props.p_cerrado} 
                                                            number={props.periodo_actual}
                                                            dias_faltantes_cierre={props.dias_faltantes_cierre}
                                                            ></CardPersonalizada>                            
                            </Grid>
                        </Grid>
            </>            
        );
}

export default BardInfo;