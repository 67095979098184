import React, { Component, Suspense } from 'react';
import {Route, Routes} from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import {Usuario} from '../services/Usuario'
import Principal from '../Pages/Principal';
import NavBar from '../components/NavBar/NavBar';
import {GetData} from '../services/GetData';
import Moment from 'moment';
import 'moment/locale/es';
import ConsolidadoResumen from '../Pages/ConsolidadoResumen';
import Docentes from '../Pages/Docentes';
import GradosDocentes from '../Pages/GradosDocentes';
import Periodos from '../Pages/Periodos';
import Consolidados from '../Pages/Consolidados';
import ListadoAlumnos from '../Pages/ListadoAlumnos';
import NotasAsignatura from '../Pages/NotasAsignatura';
import DocentesPorcenIngreso from '../Pages/DocentesPorcenIngreso';
import ListadoPrimerosPuestos from '../Pages/ListadoPrimerosPuestos';
import ListadoPrimerosPerdidos from '../Pages/ListadoPrimerosPerdidos';
import ListadoEstNivelan from '../Pages/ListadoEstNivelan';
import ListadoEstPromovidos from '../Pages/ListadoEstPromovidos';
import NotasNivelacion from '../Pages/NotasNivelacion';


class Home extends Component {



    constructor(props){
        
        super(props);
        this.state = {
            usuario_session: Usuario(),
            periodo_actual : "",
            anio_actual: "",
            fecha_cierre_p: "",
            fecha_examenf_p: "",
            dias_faltantes_cierre: 0,
            p_cerrado: false,
            bar_info_periodo: false,
            num_alumnos_matr: 0,
            num_alumnos_retir: 0,
            num_docentes: 0,
            ultima_actualizacion: "",
            loading_datos_matr: false

        }

        this.obtener_periodo_actual = this.obtener_periodo_actual.bind(this);
        this.dias_faltantes = this.dias_faltantes.bind(this);
        this.obtener_num_estudiantes = this.obtener_num_estudiantes.bind(this);
        this.fun_estudiante_mora = this.fun_estudiante_mora.bind(this);
    }

    obtener_num_estudiantes(){
      this.setState({loading_datos_matr: true});
      GetData('matriculas/numestudiantes/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
            
            this.setState({
                num_alumnos_matr: responseJSON.numestudiantes,
                num_alumnos_retir: responseJSON.num_retirados,
                num_docentes: responseJSON.num_docentes,
                loading_datos_matr: false
              })
        }  
      });

    }

    fun_estudiante_mora(){
      
      GetData('matriculas/ultimactalizacion/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
           let update = responseJSON.update;  
         
          this.setState({
            ultima_actualizacion : update
          });
        }
      });
    }     

    obtener_periodo_actual(){
        GetData('periodos/actual/', this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
              
            //let fecha_cierre = Moment(responseJSON.fecha_cierre).format('DD/MMM/YYYY');
            let fecha_cierre = responseJSON.fecha_cierre;
            
            

            this.setState({
              periodo_actual :  responseJSON.periodo,
              anio_actual : responseJSON.anio_actual,
              fecha_cierre_p : fecha_cierre,
              fecha_examenf_p : responseJSON.fecha_examenf
            }, () => {
               this.dias_faltantes();
            
            })
          }
        });
  }

  dias_faltantes(){
    let hoy = Moment().format('YYYY-MM-DD');
    
    if (this.state.fecha_cierre_p >= hoy)  {
        this.setState({
          dias_faltantes_cierre: Moment(this.state.fecha_cierre_p, "YYYYMMDD").endOf('day').fromNow(),
          bar_info_periodo: true
        })
        return;
    }else{   
      this.setState({
        p_cerrado: true,
        dias_faltantes_cierre: "Período Cerrado",
        bar_info_periodo: true
      }) 
      return;     
    }    
  }

    cerrar_session = () => {
        sessionStorage.clear();
        this.setState({usuario_session: Usuario()});
    }

    

    render() {
      
        


       /*  if (!this.state.usuario_session) {
            return (<Navigate to={'/'} />)
        } */
    
        if (this.state.usuarios_inactivo){
           return (<Navigate to={'/paginainactivo'} />)
        }
    
        return (
            <>
              <NavBar datosusuario={this.state.usuario_session} cerrarsession={this.cerrar_session.bind(this)} />                                          
               <Suspense fallback={<><div className="logoapp"></div><div className="loading"></div></>}>
            
               <Routes>
                    <Route path='/' exact element={<Principal {...this.state} />} />
                    <Route path='/consolidado/:idGrado/:idGrupo' element={<Consolidados />} exact />      
                    <Route path='consolidadoresumen/:idGrado/:idGrupo' element={<ConsolidadoResumen />} exact />                          
                    <Route path='listadoalumnos/:idGrado/:idGrupo' element={<ListadoAlumnos />} exact /> 
                    <Route path='docentesporc' element={<DocentesPorcenIngreso />} exact />      
                    <Route path='alumnosprimerosp' element={<ListadoPrimerosPuestos />} exact />      
                    <Route path='alumnosperdidos' element={<ListadoPrimerosPerdidos />} exact />      
                    <Route path='alumnosnivelan' element={<ListadoEstNivelan />} exact />      
                    <Route path='alumnospromovidos' element={<ListadoEstPromovidos />} exact />      
                    {this.state.usuario_session.id_tipo_usuario === '0' &&
                      <>
                        <Route path='docentes' element={<Docentes />} exact /> 
                        <Route path='gradosdocentes/:idDocente' element={GradosDocentes} exact /> 
                        <Route path='asignaturasgrado/:idDocente/:idGrado' element={GradosDocentes} exact /> 
                        <Route path='periodos' element={<Periodos />} exact />      
                        <Route path='planillas' element={<NotasAsignatura />} exact />      
                        <Route path='nivelacion' element={<NotasNivelacion />} exact />      
                        
                      </>
                    }
                </Routes> 

              </Suspense>       
              
            </>
       
    
        );
      }
    
      componentDidMount() {
        this.obtener_periodo_actual();
        this.obtener_num_estudiantes();
        
      }  
    
    }
    
    export default Home;