import React from 'react'
import ItemAsinaturas from './ItemAsinaturas';
import { Avatar, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';


const ItemGrados = ({ListaGrados, idDocente}) => {
  
     const colorgrado = (key) => {
        switch(true){
            case key < 5:{
                return "warning";
            }
            case key >= 5 && key <= 9:{
                return "success";
            }
            case key > 9:{
                return "danger";
            }
                                                                   
            default:
                return "warning";
                
        }
    }

  return (
    <>
    
     {ListaGrados.map((grado) => (
               <Grid item xs={12} sm={6} md={3} className="grid_item">
                      <Card className='card_asignatura'>
                            <CardHeader color={colorgrado(grado.id_grados)} stats icon  >
                                <Avatar color={colorgrado(grado.id_grados)}>
                                       <h4>{grado.nombre_grado} - {grado.num_grupo}</h4>
                                </Avatar>
                                <p >Nº Estudiantes</p>
                                <h3 >0</h3>
                            </CardHeader>
                            <CardContent>

                                    <ItemAsinaturas id_docente={idDocente}  id_grado={grado.id_grados} id_grupo={grado.num_grupo} />
                                                                 
                            </CardContent>
                            <CardActions>
                           </CardActions>
                        </Card>
                  </Grid>
       ))}
     
    </>
  )
}

export default ItemGrados