import React from 'react';

import Container from '@mui/material/Container';

import BardInfo from './Includes/BarInfo/BardInfo'
import Divider from '@mui/material/Divider';
import Grados from '../Pages/Includes/Grados/Grados';



const Principal = (props) => { 

          
        // let checked = false;
        
         return (<>   
                <div style={{ backgroundColor: '#eee', minHeight: '100vh', paddingTop: '36px' }}>
                        <Container maxWidth="lg" >
                                                  
                            <BardInfo {...props}  />
                            <br />
                            <Divider />
                       
                            <Grados {...props} />
                        </Container>
                </div>                 
         </>);

}

export default Principal;