import React, { useState } from "react";
import { TextField, LinearProgress, Grid } from "@mui/material";
import { Check, Clear } from "@mui/icons-material/";
import { PostData } from "../services/PostData";

export const InaInput = ({id, value, focusNextInput, Grado, Periodo, idAsignatura,id_matricula, usuario}) => {


        const [inasistencia, setInasistencia] = useState(value);
        const [loading, setLoading] = useState(false);
        const [guardado, setGuardado] = useState(false);
        const [error, setError] = useState(false);
        const [showDelete, setShowDelete] = useState(false);
        
        const handleChange = (event) => {
            const { value } = event.target;
            // Validar que solo se ingresen números enteros
            const regex = /^\d*$/;
            if (regex.test(value) || value === "") {
                setInasistencia(value);
            }
          };       
        
        const handleGuardar = async () => {

            if (!inasistencia){
                focusNextInput(id);
                return;
            }
            
            const [grado, grupo] = Grado.split('-');
            const datos = {
                cod_grado: grado,
                id_grupo: grupo,
                id_asignatura: idAsignatura,
                id_periodo: Periodo,
                id_matricula: id_matricula,
                ina: inasistencia,
              }
              
              setLoading(true);
              setError(false);
              const result = await PostData(`calificaciones/guardarinaadmin/`, datos, usuario);
              const responseJSON = result;
      
              if (responseJSON.error === false) {
                    setLoading(false);
                    setGuardado(true);
                    focusNextInput(id); //lleva el foco al siguente input
                    setTimeout(() => {
                        setGuardado(false);
                        //setBorrar(true)
                    }, 1600);
              }else{
                    setLoading(false);
                    setError(true);
              }
        }

        const handleFocus = (event) => {
            event.target.select();
          };

        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleGuardar();
            }
          };
        
        return (
            <Grid container alignItems="center" 
                    onMouseEnter={() => setShowDelete(inasistencia !== "")}
                    onMouseLeave={() => setShowDelete(false)}>  

            <Grid item xs={9} sm={9}>
                <TextField
                id={id}
                variant="standard"
                size="small"
                value={inasistencia}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                disabled={loading}
                inputProps={{min: 0, style: { textAlign: 'center' }}}
                onFocus={handleFocus}
                fullWidth
                />
            </Grid>
            <Grid item xs={3} sm={3} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", flexWrap: "nowrap" }}>                
                {guardado && <Check style={{ color: "green", marginRight: "0px", paddingTop: '0spx' }} />}
                {error && <Clear style={{ color: "red", marginRight: "0px", paddingTop: '0spx' }} />}
            </Grid>
            <Grid xs={12}>
                {loading && <LinearProgress style={{ marginRight: "0px" }} /> }
            </Grid>
        </Grid>
        );
  
}
