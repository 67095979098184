import { Button, Card, CardContent, CardHeader, Container, Grid, LinearProgress } from '@mui/material/'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'


import { GetData } from '../services/GetData'
import ItemGrados from './ItemGrados'

const GradosDocentes = () => {
    const [loading, setLoading] = useState(false)
    const [ListaGrados, setListaGrados] = useState([])
    let { idDocente } = useParams();

    
    useEffect(() => {
        listado_grados();
    }, [])
    

    const listado_grados = () => {
        
        
        const datos = {

        }
        setLoading(true)
        GetData('asignaciones/gradosdocente/' + idDocente, datos).then ((result) =>{
            let responseJSON = result;
            console.log(responseJSON)
            if (responseJSON.error === false){
                setListaGrados(responseJSON.grados);
                setLoading(false)
            }
        });

      }

  return (
    <>
    <Container maxWidth="lg">

        <Card>
                <CardHeader color="warning">
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                            <h4 className="H4tabla" >Listado de Docentes</h4>
                            </Grid>
                            <Grid item xs={2}>
                                <Button type="button" variant="contained" className="MargenBoton" component={Link} to={`/home/`} color="primary"> Regresar </Button>
                            </Grid>
                        </Grid>
                </CardHeader>
                
                <CardContent>

                    <LinearProgress style={{visibility: loading ? 'visible' : 'hidden' }} />
                    <Grid container spacing={3}>
                
                        <ItemGrados ListaGrados={ListaGrados} idDocente={idDocente} />
                
                    </Grid>

            </CardContent>

        </Card>
    </Container>      
        
    </>
  )
}

export default GradosDocentes