import React, {Component} from 'react';
import Grado from "./Grado";
import {GetData} from '../../../services/GetData';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid } from '@mui/material';

class Grados extends Component {

    constructor(props){
        super(props);
        this.state = {
            tipo_usuario: this.props.usuario_session.tipo_usuario,
            listagrados : null,
            loading: false,
            id_periodo: 0
        }
        this.listar_grados = this.listar_grados.bind(this);
        this.obtener_periodo_actual = this.obtener_periodo_actual.bind(this);
    }

    colorgrado(key){
        switch(true){
            case key < 5:{
                return "warning";
            }
            case key >= 5 && key <= 9:{
                return "success";
            }
            case key > 9:{
                return "danger";
            }
                                                                   
            default:
                return "warning";
                
        }
    }

    

    obtener_periodo_actual() {

        GetData('periodos/actual/', this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
             this.setState({id_periodo: responseJSON.periodo}, () => {
                this.listar_grados();
            });
          }  
            
        });
    }

    listar_grados(){
        
        this.setState({loading: true});
        
        GetData('asignaciones/todosgrados/', null).then ((result) =>{
              let responseJSON = result;
              if (responseJSON.error === false){
                            
                    let listagrados = responseJSON.grados.map(
                        (item, key) => <Grado
                                key = {key}
                                color={this.colorgrado(key)}
                                id_grado = {item.id_grado}
                                id_grupo = {item.id_grupo}
                                grado = {item.nombre_grado}
                                grupo = {item.num_grupo}
                                n_studen= {item.num_stud}
                                num_desert={item.num_desert}
                                num_asig={item.num_asig}
                                porc_notas={item.porc_notas}
                                alumnos_mora={item.alumnos_mora}
                                link_consolidado={""}
                                link_inform={""}
                                periodo_actual={this.state.id_periodo}
                                tipo_usuario={this.state.tipo_usuario}

                        />
                    )
                    this.setState({listagrados}, () => {
                        this.setState({loading: false})
     
                    })
              }
        });

    }    

    render() {
        return (<>
           
                <LinearProgress style={{visibility: this.state.loading ? 'visible' : 'hidden' }} />
                <Grid spacing={3} container className='marge-top-15'>
                            {this.state.listagrados}                              
                </Grid>
                

        </>)
    }

    componentDidMount(){
        this.obtener_periodo_actual();
        
    }

}

export default Grados;