export function getToken() {    
    const usuario = JSON.parse(localStorage.getItem('userData'));
    return usuario ? usuario.token : '';
  }
  
  export function getID() {
    const usuario = JSON.parse(localStorage.getItem('userData'));
    return usuario ? usuario.id_usuario : '';
  }
  
  export function getSede() {
    const usuario = JSON.parse(localStorage.getItem('userData'));
    return usuario ? usuario.sede : '';
  }

  export function Usuario() {
    const usuario = JSON.parse(localStorage.getItem('userData'));
    return usuario ? usuario : '';
  }

