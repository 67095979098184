import React, { useEffect, useState } from 'react'
import {  LinearProgress, TableRow } from '@mui/material/'
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ItemDocentePorcCon from './ItemDocentePorcCon';
import { GetData } from '../services/GetData';
import pLimit from 'p-limit';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ItemDocentePorc = ({listaDocentes}) => {
    
  const [porcentajes, setPorcentajes] = useState([]);
  const [loading, setLoading] = useState(true);

  
    const fetchPorcentaje = async (idDocente) => {
      try {
        await delay(2000);
        const result = await GetData('asignaciones/porcingnotasasignadmin/' + idDocente + '/', null);
        return result.porcentaje;
      } catch (error) {
        console.error(`Error al obtener el porcentaje para el docente ${idDocente}:`, error);
        return 0;
      }
    };

    useEffect(() => {
      const limit = pLimit(5); // Límite de solicitudes concurrentes
  
      const fetchData = async () => {
        const results = await Promise.all(
          listaDocentes.map((docente) => fetchPorcentaje(docente.id_docente))
        );
  
        setPorcentajes(results);
        setLoading(false);
      };
  
      fetchData();
    }, [listaDocentes]);
    

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '5px 16px',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



  return (
    <>
      {listaDocentes.map((docente, index) => (
        <StyledTableRow key={docente.id_docente} className="TextoTabla">
          <StyledTableCell align="left">{docente.id_docente}</StyledTableCell>
          <StyledTableCell align="left">{docente.nombres_apellidos_doc}</StyledTableCell>
          <StyledTableCell align="left">{docente.telefonos_doc}</StyledTableCell>
          <StyledTableCell align="center">
          {loading ? (
              <LinearProgress />
            ) : (
              <LinearProgress variant="determinate" value={porcentajes[index]}  />
            )}
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

export default ItemDocentePorc;