import React, { createContext, useState, useEffect } from 'react';

// Crea el contexto de sesión
export const SessionContext = createContext();

// Crea el proveedor de sesión
export const SessionProvider = ({ children }) => {
  // Obtén los datos de usuario almacenados en el almacenamiento local al cargar la aplicación
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')) || null);

  // Función para iniciar sesión
  const login = (userData) => {
    setUser(userData);
  };

  // Función para cerrar sesión
  const logout = () => {
    setUser(null);
  };

  // Guarda los datos de usuario en el almacenamiento local al actualizar el estado de usuario
  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(user));
  }, [user]);

  // Define los valores del contexto

  // Retorna el proveedor del contexto con los hijos proporcionados
  return (
    <SessionContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </SessionContext.Provider>
  );
};
