import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from './layouts/Home';
import LoginPage from './Pages/LoginPage';
import { SessionContext } from './SessionContext';
import { useContext } from 'react';

function Rutas(){
    
    const { user } = useContext(SessionContext);

    return (
        <Router>
            <Routes>
                 <Route path="/" element={  !user ? (<LoginPage />):( <Navigate to="/home"/>)} />
                 <Route path="/home/*" element={ user ? (<Home />):( <Navigate to="/"/>)} />
                 <Route path="*" element={<LoginPage />} />
            </Routes>
        </Router>

)}

export default Rutas;
