import { getToken, getID, getSede } from './Usuario';

export function PostData(type, userData){    
    const url_api = process.env.REACT_APP_URL_SERVICIOS;
    const token = getToken();
    const id = getID();
    const sede = getSede();


    return new Promise((resolve, reject) => {
        fetch(url_api+type+id+'/'+token+'/'+sede,{
            method: 'POST',
            body: JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
           userData.error_login =  true;
           reject(error);
           
        })
    })    
}