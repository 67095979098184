import { List, ListItem, ListItemText } from '@mui/material/';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import { GetData } from '../services/GetData';

const ItemAsinaturas = ({id_docente, id_grado, id_grupo}) => {

    const [ListaAsignaturas, setListaAsignaturas] = useState([]);

    useEffect(() => {
        ConsultarAsignaturas();
    
    }, [])
    

  const ConsultarAsignaturas = () => {

    GetData('asignaciones/asignaturasdocente/' + id_docente + "/" + id_grado + "/" + id_grupo + "/0", null).then ((result) =>{
        let responseJSON = result;
        
        if (responseJSON.error === false){
            setListaAsignaturas(responseJSON.asignaturas);
        }
    });

  }  

  return (
    
        <List component="nav">
            {ListaAsignaturas.map((asignatura) => (
               
                    <ListItem button component={Link} to={`/home/periodos/`}>
                            <ListItemText
                                primary={asignatura.nombre_asignatura}                    
                            />
                    </ListItem>
         
            ))}
        </List>
    
  )
}

export default ItemAsinaturas