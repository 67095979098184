import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, Dialog, AppBar, Toolbar, IconButton, Container, Card, Tooltip} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { NotasInput } from '../components/NotasInput';
import { InaInput } from '../components/InaInput';
import { GetData } from '../services/GetData';
import CloseIcon from '@mui/icons-material/Close';
import { SessionContext } from '../SessionContext';
import { useContext } from 'react';
import { PostData } from '../services/PostData';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import { NivelacionInput } from '../components/NivelacionInput';
import { FechaInput } from '../components/FechaInput';

const NotasNivelacion = () => {    
    
    const [Grado, setGrado] = React.useState([]);
    const [Loading, setLoading] = React.useState(false);
    const [LoadingAsig, setLoadingAsig] = React.useState(false);
    const [Alumnos, setAlumnos] = useState([]);
    const [promedios, setPromedios] = useState({});
    const [ListaGrados, setListaGrados] = useState([]);
    const [ListaAsignaturas, setListaAsignaturas] = useState([]);
    const [Asignatura, setAsignatura] = useState(0);
    const [UrlImpresion, setUrlImpresion] = useState('');
    const [openImpresion, setopenImpresion] = useState(false)    
    
    const { user: usuario } = useContext(SessionContext);
    const [Periodo, setPeriodo] = useState(usuario.periodo);
    const [isPeriodOpen, setisPeriodOpen] = useState(false);
    

    

    const ConsultarPlanilla = async () => {
      try {
        setLoading(true);    
    
        if (!Asignatura) {
          return () => {};
        }

        console.log("asignatura", Asignatura)
        const [grado, grupo] = Grado.split('-');
        const datos = {
          cod_grado: grado,
          id_grupo: grupo,
          id_asignatura: Asignatura,
        };
    
        setAlumnos([]);
        const result = await GetData('matriculas/lisaniveladosgrado/' + grado + '/' + grupo + '/' + Asignatura + '/');
        const responseJSON = result;
    
        setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_calificaciones.php?grado=' + grado + '&grupo=' + grupo + '&asig=' + Asignatura + '&periodo=' + Periodo + '&token=' + usuario.token);
    
        if (responseJSON.error === false) {
            setAlumnos(responseJSON.alumnos);
            setLoading(false);
        } else {
          console.log("Error al cargar las asignaciones");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          padding: '6px 16px', // Establece el padding en cero
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          padding: '4px 16px', // Establece el padding en cero
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      
      const calcularPromedio = (num, nota, row, index) => {

        const notas = [row.nota_indic1, row.nota_indic2, row.nota_indic3, row.nota_indic4];
        
        switch (num){
            case 1:
                notas[0] = nota;
                row.nota_indic1 = nota;
                break;
            case 2:
                notas[1] = nota;
                row.nota_indic2 = nota;
                break;
            case 3:
                notas[2] = nota;
                row.nota_indic3 = nota;
                break;
            case 4:
                notas[3] = nota;
                row.nota_indic4 = nota;
                break;

        }
        const notasValidas = notas.filter(nota => nota !== '');
        const promedio = notasValidas.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / notasValidas.length || 0;       
        document.getElementById(`promedio_${index}`).innerHTML = promedio.toFixed(2);
        const cell = document.getElementById(`promedio_${index}`);
        if (promedio < 3)
            cell.classList.add('texto-rojo');
        else
            cell.classList.remove('texto-rojo');    

      }

      function calcularPromedioCelda (row) {
          const notas = [row.nota_indic1, row.nota_indic2, row.nota_indic3, row.nota_indic4];
          const notasValidas = notas.filter(nota => nota !== '');
          const promedio = notasValidas.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / notasValidas.length || 0;       
          return promedio.toFixed(2);
      }

      const focusNextInput = (currentInputId) => {
        const currentIndex = parseInt(currentInputId.replace('txt', ''));
        const nextIndex = currentIndex + 1;
        const nextInput = document.getElementById(`txt${nextIndex}`);
        if (nextInput) {
          nextInput.focus();
        }
        
      };
      
      const handleCloseImpresion = () => {
        setopenImpresion(false);
      };

      const AbrirPlanillaFinal = () => {
            const [grado, grupo] = Grado.split('-');

            setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_final.php?grado=' + grado + '&grupo=' + grupo + '&asig=' + Asignatura + '&token=' + usuario.token);
            setopenImpresion(true)
      }
      const AbrirPlanilla = () => {
            const [grado, grupo] = Grado.split('-');
            setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_calificaciones.php?grado=' + grado + '&grupo=' + grupo + '&asig=' + Asignatura + '&periodo=' + Periodo + '&token=' + usuario.token);
            setopenImpresion(true)
      }
  
     
      useEffect(() => {
            GetData("asignaciones/listgradosadmin/", usuario).then((result) => {
              let responseJSON = result;
              if (responseJSON.error === false) {
                  const datos = responseJSON.grados;
                  setListaGrados(datos)
                  setGrado(datos[0].cod_grado_grupo);
              }
            })
       
      }, [usuario])

      useEffect(() => {
    
        if (Grado.length > 0){
            const [grado, grupo] = Grado.split('-');
            setAsignatura([]);
            setLoadingAsig(true)
            GetData("asignaciones/listasignagradogrupo/" + grado + '/' + grupo+'/', usuario).then((result) => {
              let responseJSON = result;
              if (responseJSON.error === false) {
                  const datos = responseJSON.asignaturas;
                  setListaAsignaturas(datos)
                  setAsignatura(datos[0].id_asignatura);
                  setLoadingAsig(false)
              }
            })
      }else
      return () => {}; // Función de limpieza vacía

    
      }, [Grado, usuario])
         
       
  return (
    <> 
         <Container maxWidth="lg">

                    
              <Card sx={{marginTop: '20px', marginBottom: '20px'}}>
                    <Grid container alignItems="center" >
                         <Grid item xs={12} sx={{color: '#fff', padding: '5px', background: 'linear-gradient(90deg, rgba(9,53,121,1) 60%, rgba(138,184,228,1) 100%)',}}>
                         <div  >
                             <Typography variant="h6" color="inherit">Nivelaciones Finales</Typography> 
                          </div> 
                         </Grid> 
                         <Grid item xs={2}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                              <InputLabel id="sel_grado_grupo">Grados</InputLabel>
                              <Select
                                labelId="sel_grado_grupo"
                                id="sel_grado_grupo"
                                value={Grado}
                                onChange={(e) => setGrado(e.target.value)}
                                label="Grado"
                                
                              >
                                {ListaGrados.map((grado,index) => (
                                  <MenuItem key={index} value={grado.cod_grado_grupo}>
                                    {grado.nombre_grado} - 0{grado.id_grupo}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          
                          <Grid item xs={4}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                              <InputLabel id="sel_asignaturas">Asignaturas</InputLabel>
                              <Select
                                labelId="sel_asignaturas"
                                id="sel_asignaturas"
                                value={Asignatura}
                                onChange={(e) => setAsignatura(e.target.value)}
                                label="Periodos"
                                disabled={LoadingAsig}
                              >
                                 {ListaAsignaturas.map((asig, index) => {
                                      const nombreAsignatura = asig.nombre_asignatura.split('(')[0].trim();
                                      return (
                                        <MenuItem key={`asig_${index}`} value={asig.id_asignatura}>
                                          {nombreAsignatura}
                                        </MenuItem>
                                      );
                                    })}
                              </Select>
                              {LoadingAsig && <LinearProgress />}
                            </FormControl>  
                          </Grid>
                          <Grid item xs={2}>
                           
                          </Grid>
                          <Grid item xs={4} sx={{paddingTop: '17px'}}>                            
                              <Button variant="outlined" onClick={()=> ConsultarPlanilla()} size="small" startIcon={<SearchIcon />}>Consultar</Button>                                                       
                          </Grid>
                          
                    </Grid>
                    {Loading && <LinearProgress  />}
                    <TableContainer component={Paper}>
                    <Table size="small" aria-label="customized table">
                        <TableHead className='headerTable'>
                        <TableRow style={{backgroundColor: 'brown'}}>
                            <StyledTableCell  width="50px">N°</StyledTableCell>
                            <StyledTableCell align="left">Alumno</StyledTableCell>
                            <StyledTableCell align="center" width="100px">Nota Final</StyledTableCell>
                            <StyledTableCell align="center" width="100px">Nivelación</StyledTableCell>
                            <StyledTableCell align="center" width="200px">Fecha Niv.</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {Alumnos.map((row, index) => (
                            <StyledTableRow key={row.id_matricula} >
                            <StyledTableCell component="th" scope="row" width="50px">
                                {row.id_matricula}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.nombres_apellidos_al}</StyledTableCell>
                            <StyledTableCell align="center" width="70px">{row.nota_final}
                            </StyledTableCell>
                            <StyledTableCell align="center" width="70px">
                              {row.nota_final < 3 &&
                                <NivelacionInput id={`txt${index * 5 + 3}`} Grado={Grado}  idAsignatura={Asignatura} id_matricula={row.id_matricula} usuario={usuario} focusNextInput={focusNextInput} value={row.nivelacion} />
                              }
                            </StyledTableCell>     
                            <StyledTableCell align="center" width="70px">
                                {row.nota_final < 3 &&
                                  <FechaInput id={`txt${index * 5 + 3}`} Grado={Grado}  idAsignatura={Asignatura} id_matricula={row.id_matricula} usuario={usuario} focusNextInput={focusNextInput} value={row.fecha_nivel} />
                                }
                            </StyledTableCell>                       
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                 
                    </Card>
        </Container>

        <Dialog
                    fullScreen
                    open={openImpresion}
                    onClose={handleCloseImpresion}
                    
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseImpresion}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Button color="inherit" onClick={handleCloseImpresion}>
                            Cerrar
                        </Button>
                    </Toolbar>
                    </AppBar>
                    <div>
                        <iframe src={UrlImpresion} title='Imprimir Planilla' className="inframemax">
                        </iframe> 
                    </div>
                </Dialog>
    </>
  )
}

export default NotasNivelacion