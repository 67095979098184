import React, { useEffect, useState } from 'react'
import { Grid, Typography, LinearProgress, Card, Container, CardContent, Button  } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GetData } from '../services/GetData';
import { SessionContext } from '../SessionContext';
import { useContext } from 'react';



const ListadoPrimerosPuestos = () => {
  
    const [Alumnos, setAlumnos] = useState([]);
    const [Loading, setLoading] = React.useState(false);
    const { user: usuario } = useContext(SessionContext);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          padding: '6px 16px', // Establece el padding en cero
          color: theme.palette.common.white,
          backgroundColor: '#1976d2',
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          padding: '4px 16px', // Establece el padding en cero
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      

      const irA = (url, externa=false) => {
            window.open(url, "_blank");    
      }

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
      
          const result = await GetData(`matriculas/primerospuestosfinal/`);
          const responseJSON = result;
      
          if (responseJSON.error === false) {
            setAlumnos(responseJSON.alumnos);
            setLoading(false);
          } else {
            console.log("Error al cargar las asignaciones");
          }
        };
      
        fetchData();
      }, []);

     
      
      const abrirExcel = () => {
                 window.open(process.env.REACT_APP_URL_INFORMES + '/listado_alumnos_puestos_final.php?token='+usuario.token, '_blank');  
      };

  return (
    <>
     <Container maxWidth="lg">
        <Card sx={{marginTop: '20px'}}>
              <div className='barra_titulo' >
                  <Grid container spacing={2}>
                       <Grid item xs={6}>
                            <Typography variant="h6" color="inherit">Alumnos Primeros Puestos Período Final </Typography>                                            
                        </Grid>
                        <Grid item xs={4} style={{textAlign: 'right'}}>
                        <Button variant="contained" color="success" onClick={()=> abrirExcel()} >Descargar</Button>
                        </Grid>

                    </Grid>
              </div>
            <CardContent>          
                    {Loading && <LinearProgress  />}
                    <TableContainer component={Paper} size="small" >
                    <Table size="small" aria-label="customized table">
                        <TableHead className='headerTable'>
                        <TableRow>
                            <StyledTableCell>N°</StyledTableCell>
                            <StyledTableCell align="left">Grado</StyledTableCell>
                            <StyledTableCell align="left">Grupo</StyledTableCell>
                            <StyledTableCell align="left">Alumno</StyledTableCell>
                            <StyledTableCell align="left">Promedio</StyledTableCell>
                            <StyledTableCell align="left"></StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {Alumnos.map((row, index) => (
                            <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row" width="100px">
                                {index+1}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.num_grado}</StyledTableCell>
                            <StyledTableCell align="left">{row.id_grupo}</StyledTableCell>
                            <StyledTableCell align="left">{row.nombres_apellidos_al}</StyledTableCell>
                            <StyledTableCell align="left">{row.promedio_total}</StyledTableCell>
                            <StyledTableCell align="center">
                                        
                            </StyledTableCell>

                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
            </CardContent>                
          </Card>
       </Container>     

    </>
  )
}

export default ListadoPrimerosPuestos;