import React, { useContext, useEffect, useState } from 'react'
import { PostData } from '../services/PostData'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SessionContext } from '../SessionContext';

import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingScreen from 'react-loading-screen'
import { MenuItem, Select, Stack } from '@mui/material';




const LoginPage = () => {

  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [Sede, setSede] = useState('')
  const { setUser } = useContext(SessionContext);
  const [Loading, setLoading] = useState(false)
  const [LoadingScr, setLoadingScr] = useState(true)

  const navigate = useNavigate();

 
  useEffect(() => {
    setLoadingScr(false)
  }, [])
    

  const login = (e) => {
      e.preventDefault();

      const datos = {
            email: Email,
            password: Password
      }
     

        setLoading(true)
        PostData('login/loginadmin/' + Sede, datos).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
               setLoading(false)
               sessionStorage.setItem('userData',JSON.stringify(responseJSON));
               setUser(result); 
           }else{
            setLoading(false)
            Swal.fire({
              title: 'Usuario no válido',
              text: 'Los Datos de ingreso no son válidos, por favor verifique.',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
            
        }
      })

  }

  const styles = theme => ({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
  });

  return (
        <>
            <LinearProgress  style={{display: Loading ? '' : 'none' }} />

            <div className="main login">
          
            <CssBaseline />

            <LoadingScreen
                    loading={LoadingScr}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='/images/LogoCnotasGr.png'
                    text='...'
                    > 

            <Paper className="paper" >
           
              <img src="/images/LogoCnotas.png" className="avatar" alt="logo" />

              <Typography component="h1" variant="h5">
                Ingreso de Coordinador
              </Typography>

              <form className="formulario"  onSubmit={login}>
              <FormControl margin="normal" required fullWidth>      
                                    <InputLabel htmlFor="sel_sede">Sede</InputLabel>
                                   
                                    <Select
                                        labelId="sel_sede"
                                        id="sel_sede"
                                        value={Sede}
                                        onChange={(e) => setSede(e.target.value)}
                                        label="Grado"
                                    >
                                        <MenuItem key={0} value="default">Simón Bolívar</MenuItem>
                                        <MenuItem key={1} value="dbinmaculada">María Inmaculada</MenuItem>
                                        <MenuItem key={2} value="dbmiraflores">Miraflores</MenuItem>
                                        <MenuItem key={3} value="dbsfrancisco">San Francisco</MenuItem>
                                        <MenuItem key={4} value="dbsmarcos">San Marcos de León</MenuItem>

                                    </Select>

                </FormControl>                                
                                


                <FormControl margin="normal" required fullWidth>
                  <TextField id="email" label="Correo Electrónica" variant="standard" onChange={e => setEmail(e.target.value)} autoFocus />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <TextField id="password" label="Contraseña" variant="standard" onChange={e => setPassword(e.target.value)}  type="password" />
                </FormControl>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={styles.submit}
                  disabled={Email === '' || Password === '' || Sede === ''}
                >
                  Ingresar
                </Button>
              </form>
            </Paper>
           
            </LoadingScreen>   
            </div>           
        </>
  )
}

export default LoginPage