import React from 'react'
import {  TableRow, IconButton } from '@mui/material/'
import { styled } from '@mui/material/styles';
import { VpnKey } from '@mui/icons-material/';
import Tooltip from '@mui/material/Tooltip';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import GradingIcon from '@mui/icons-material/Grading';
import PrintIcon from '@mui/icons-material/Print';

const ItemDocente = ({listaDocentes, ConfirmarCrearUsuario, ListaCargaDoc, DatosDocente, PlanillasDocente}) => {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '5px 16px',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

      

  return (
    <>
     {listaDocentes.map((docente) => (
            <StyledTableRow key={docente.id_docente} className="TextoTabla">
              <StyledTableCell align="left">{docente.id_docente}</StyledTableCell>
              <StyledTableCell align="left">{docente.nombres_apellidos_doc}</StyledTableCell>
              <StyledTableCell align="left">{docente.telefonos_doc}</StyledTableCell>
              <StyledTableCell align="left">{docente.email_doc }</StyledTableCell>
              <StyledTableCell align="center">
                <Tooltip title="Datos del Docente">
                  <IconButton aria-label="Datos del Docente" color='primary' onClick={() => DatosDocente(docente.id_docente)}>
                    <HowToRegIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Planilla Docente">
                  <IconButton aria-label="Planilla Docente" color='primary' onClick={() => PlanillasDocente(docente.id_docente)}>
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Carga Académica">
                  <IconButton aria-label="Carga Académica" color='primary' onClick={()=> ListaCargaDoc(docente.id_docente)}>
                    <GradingIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Genear Contraseña">
                  <IconButton aria-label="Crear" color='primary' onClick={()=> ConfirmarCrearUsuario(docente.id_docente, docente.nombres_apellidos_doc) }>
                    <VpnKey />
                  </IconButton>
                </Tooltip>
                
              </StyledTableCell>
            </StyledTableRow>
          ))}
    </>
  )
}

export default ItemDocente