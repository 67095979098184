import React from 'react';
import purple from '@mui/material/colors/purple';

import { Usuario } from '../../../services/Usuario';
import { Grid } from '@mui/material';
import CardGrado from '../../../components/CardGrado';



const Grado = ({color, id_grado, id_grupo, grado, grupo, n_studen, num_desert, num_asig, porc_notas, periodo_actual, tipo_usuario}) => { 

    const usuario_session =  Usuario();

    const url_informes = process.env.REACT_APP_URL_INFORMES;

    const gradoc = grado + " - " + grupo;
    
      
      const ITEM_HEIGHT = 48;

    
    const primary = purple[500]; // #F44336

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        
    };

    const admin_habilitado = parseInt(tipo_usuario) === 0 ? true : false;


    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
            <Grid item xs={12} sm={6} md={3}>
                <CardGrado number={n_studen} periodo_actual={periodo_actual} title="Nº Estudiantes" grado={id_grado} grupo={grupo} textogrado={gradoc} num_asig={n_studen} porc_notas={porc_notas} num_desert={num_desert} />                
            </Grid>            
    </>);
}

export default Grado;